import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import ContactFormLang from "@components/contactformlang";
import { LanguageFormWrapper, LanguageFormInner, FormSkeleton } from "./style";
import { SkeletonForm, SkeletonText } from "../../../components/skeleton";
const ContactFormLang = React.lazy(() => import('@components/contactformlang'));

export default function LanguageForm() {
  const [hideSkeleton, setHideSkeleton] = useState(true);

  setTimeout(() => {
    setHideSkeleton(false);
  }, 3500)

  return (

    <LanguageFormWrapper>
      <Container>
        <LanguageFormInner>
          <Row>
            <Col>
              {hideSkeleton &&
                <FormSkeleton>
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="80px" inputHeight="66px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="100px" inputHeight="40px" inputWidth="200px" mb="4px" />
                  <SkeletonText height="18px" width="100%" mb="4px" />
                </FormSkeleton>
              }
              {typeof window !== 'undefined' && (
                <React.Suspense fallback={<div />}>
                  <ContactFormLang />
                </React.Suspense>
              )}
            </Col>
          </Row>
        </LanguageFormInner>
      </Container>
    </LanguageFormWrapper>

  );
}
