import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
// import { Container, Row, Col } from '@ui/wrapper'
import { HeadingType, TextType, ImageType } from '@utils/types'
import {
  PageHeaderWrap,
  PageHeaderContent,
  StyledTitle,
  StyledDesc,
  StyledSubtitle,
  PageHeaderImgWrap,
  StyledBG,
  TagLine,
  ScrollTosection
} from './style'
import Image from '@ui/image'
import Text from '@ui/text'
import Button from '@ui/button'
import GlobalRating from '@components/global-rating' 
import { SkeletonText } from '../../../components/skeleton'

const PageHeader = ({ data }) => {
  const [hideSkeleton, setHideSkeleton] = useState(true);
  const scroolSection = id => {
    if (!!document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setHideSkeleton(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <PageHeaderWrap>
      <Container>
        <Row>
          <Col lg={7} className='d-flex align-items-center'>
            <PageHeaderContent>
            {hideSkeleton ?
                <SkeletonText height="40px" width="100%" mb="30px" />:<>
                   {data?.headings?.[0] && (
                       <Suspense fallback={<div></div>}> 
                <StyledTitle as={data.headings[0]?.level}>
                  {data.headings[0]?.content}
                </StyledTitle>
                </Suspense>
              )}
              {data?.texts?.[0] && (
                <StyledDesc>{data.texts[0]?.content}</StyledDesc>
              )}
              {data?.texts?.[1] && (
                <StyledSubtitle>{data.texts[1]?.content}</StyledSubtitle>
              )}
              {data.buttons &&
                data?.buttons.slice(0, 1)?.map(({ id, content, ...rest }) => (
                  <Button key={id} m='7px' {...rest}>
                    {content}
                  </Button>
                ))}

              {data.buttons &&
                data?.buttons.slice(1, 2)?.map(({ path, content, ...rest }) => (
                  <Button 
                    key={path}
                    onClick={() => scroolSection(path)}
                    m='7px'
                    className='scrollBtn'
                    {...rest}
                  >
                    {content}
                  </Button>
                ))}
                </>}
                  <GlobalRating pageHeader={data?.id}></GlobalRating>
            </PageHeaderContent>
          </Col>
          <Col lg={5}>
            <PageHeaderImgWrap>
              <TagLine>
                Grow your wor<Text as='span'>l</Text>d.
              </TagLine>
              {!!data?.images?.[0]?.src && (
                <StyledBG>
                  {typeof window !== 'undefined' &&
                  window.location.href.includes(
                    'brazilian-portuguese-translation-services/'
                  ) ? (
                    <StaticImage
                      src='../../../data/images/hero/french-tr-hero.svg'
                      alt='french-tr-hero'
                    />
                  ) : (
                    <Image
                      src={data.images?.[0]?.src}
                      alt={data.images[0]?.alt || 'Page Header'}
                    />
                  )} 
                </StyledBG>
              )}
            </PageHeaderImgWrap>
          </Col>
        </Row>
      </Container>

      <ScrollTosection id='scrollTosection'></ScrollTosection>
    </PageHeaderWrap>
  )
}

PageHeader.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType))
  })
}

export default PageHeader
