import styled, { device } from "@styled";

export const LanguageTranslatorsWrapper = styled.section`
    padding: 50px 0;

    ${device.medium} {
      padding: 80px 0;
    }

    ${device.large} {
      padding: 100px 0;
    }
`;
export const LanguageTranslatorsInner = styled.div`
  ul {
    list-style: disc;
    padding-left: 1rem;
  }

  .introText p {
    font-size: 17px;
  }
  .row{
    grid-gap:30px 0;
    ${device.large} {
      grid-gap:0;s
    }
`;
export const LanguageTranslationList = styled.div``;
export const LanguageTranslationItem = styled.div`
  color: #fff;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
