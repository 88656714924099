import styled from "@styled";

export const BannerRatingWrapper = styled.div`
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    grid-gap: 10px 20px;
    margin-top: 40px;
    @media (max-width: 1400px) {
        margin-top: 30px;
    }
    @media (max-width: 768px) {
        margin-top: 20px;
    }
    .ratingWrap {
        &:nth-child(2){
            position: relative;
            margin-right: 20px;
            overflow: visible;
            &::before{
                content: "";
                width: 1px;
                height: 100%;
                position: absolute;
                right: -20px;
                background: #f5f5f5;
                opacity: 0.3;
                z-index: 31;
                display: block;
            }
        }   
        img{
            max-height:67px;
        } 
        .ratingPoints{
            display:flex;
            align-items:center;
            img{
                max-height:22px;
                @media (max-width: 1600px) {
                    max-height:20px;
                }
            }
        }  
        .ratingText{
            color:#fff;
            font-size:20px;
            font-weight: 400;
            font-family: Centurygothic;
            @media (max-width: 1400px) {
                font-size:18px;
            }
            @media (max-width: 768px) {
                font-size:16px;
            }
            &.points{
                margin-left: 8px;
            }
        }
    }
`;
