import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LanguageTranslatorsWrapper, LanguageTranslatorsInner } from "./style";
// const Image = React.lazy(() => import('@ui/image'));
// const Text = React.lazy(() => import('@ui/text'));
// const SectionTitle = React.lazy(() => import('@ui/section-title'));
import Image from "@ui/image";
import SectionTitle from "@ui/section-title"
import Text from "@ui/text"

export default function LanguageTranslators({ data }) {
  return (
    // typeof window !== 'undefined' && (
    //   <React.Suspense fallback={<div></div>}>
    <LanguageTranslatorsWrapper id={data.section}>
      <LanguageTranslatorsInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle className="introText"
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <Image src={data.images[0].src} alt="automated" />
            </Col>
            <Col lg={7}>
              {data.items.map((data, index) => (
                <Text key={index} dangerouslySetInnerHTML={{ __html: data.title }}></Text>
              ))}
            </Col>
          </Row>
        </Container>
      </LanguageTranslatorsInner>
    </LanguageTranslatorsWrapper>
//     </React.Suspense>
// )
  );
}
